<template>
  <button class="btn" @click="toDownload">
    <slot>{{ useTranslateI18n("newHome.hero.btn") }}</slot>
    <Arrow v-if="showIcon" class="icon" />
  </button>
</template>

<script lang="ts" setup>
import Arrow from "@/assets/images/pages/newHome/home-hero-btn-arrow.svg"
import { PropType } from "vue"
import { DOWNLOAD_PAGE_PATH } from "~/constant/route"

const props = defineProps({
  isCustomClick: {
    type: Boolean as PropType<boolean>,
    default() {
      return false
    }
  },
  showIcon: {
    type: Boolean,
    default() {
      return true
    }
  }
})

const emits = defineEmits(["btnClick"])

const toDownload = () => {
  if (!props.isCustomClick) {
    navigateTo(unref(DOWNLOAD_PAGE_PATH))
  } else {
    emits("btnClick")
  }
}
</script>

<style scoped lang="scss" src="./index.scss" />
