<template>
  <div :class="['home-page']">
    <PagesNewHomeHero />
    <PagesNewHomeSimplifyLife />
    <PagesNewHomeCommonAnimationStickyCover>
      <LazyPagesNewHomeKols />
    </PagesNewHomeCommonAnimationStickyCover>
    <PagesNewHomeAdvantage />
    <!-- <LazyPagesNewHomeBrank /> -->
    <PagesNewHomeScene />
    <PagesNewHomeEnjoyBox>
      <template #top>
        <PagesNewHomeFeature />
      </template>
      <template #default="{ aniParams }">
        <PagesNewHomeEnjoy :ani-params="aniParams" />
      </template>
      <template #bottom>
        <PagesNewHomeCommunity />
      </template>
    </PagesNewHomeEnjoyBox>
    <PagesNewHomeEnding />
    <PagesNewHomeCommonAnimationTranslateScroll>
      <PagesNewHomeCommonFooter />
    </PagesNewHomeCommonAnimationTranslateScroll>
    <PagesHomeSidebar v-if="!hiddenSiderbar" />
    <PagesNewHomeGiftBox v-if="hallows_open" />
  </div>
</template>

<script lang="ts" setup>
import { scrollData } from "~/constant/pages/newHome"
import { useAllActivitys } from "~/constant/useAllActivitys"

const { hallows_open } = useAllActivitys()

definePageMeta({
  layout: "home",
  middleware: ["new-home"]
})
useNavBarScroll(scrollData)

onBeforeUnmount(() => {
  // 移除导航栏阴影
  useNavBarShadowShow().value = false
})
// 第二屏展示侧边栏
const hiddenSiderbar = ref(true)
const { y } = useWindowScroll()
const { height } = useWindowSize()
watch(y, (v) => {
  if (v > height.value) {
    hiddenSiderbar.value = false
  } else {
    hiddenSiderbar.value = true
  }
})
</script>

<style scoped lang="scss">
:deep(*) {
  user-select: unset;
}
:deep(.sticky-animation-box) {
  .sticky-box {
    background: #fcfcf3;
  }
}
</style>
