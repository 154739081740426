<template>
  <div :id="HERO_ID" class="hero-page" :class="{ 'dark-light': showDark }">
    <PagesNewHomeCommonContainer :class="['hero-container', { 'show-ani': showAni }]">
      <div class="hero-content">
        <div class="bg-area"></div>
        <div class="title-area">
          <template v-if="showDark">
            <h1 class="title">
              <div class="cls-box">
                <span class="title-text">{{ useTranslateI18n(`payment.blackFridays.t1`) }}</span>
              </div>
              <div class="cls-box">
                <span class="title-text-2">{{ useTranslateI18n(`payment.blackFridays.t2`) }}</span>
              </div>
            </h1>

            <div class="sub-title">
              <div v-html="blackFridayDesc"></div>
            </div>

            <div class="gift">
              <CommonImage :src="Gift" background="transparent" />
            </div>

            <!-- @click="toDownload" -->
            <PagesNewHomeCommonToDownloadBtn
              class="to-download-btn black-go-pricing-btn"
              :is-custom-click="true"
              @btn-click="goPricing"
            >
              {{ useTranslateI18n(`payment.topCard[1].btn[0]`) }}
            </PagesNewHomeCommonToDownloadBtn>
          </template>

          <template v-else>
            <h1 class="title">
              <!-- used to reduce CLS -->
              <div class="cls-box">
                <span class="title-text">{{ useTranslateI18n("newHome.hero.title1") }}</span
                ><span>
                  <div class="title-img-1-ani">
                    <CommonImage
                      class="title-img-1"
                      :src="getCloudAssets('/images/pages/newHome/home-hero-title-img-1.png')"
                      background="transparent"
                    />
                  </div>
                </span>
              </div>
              <span class="title-text-2">{{ useTranslateI18n("newHome.hero.title2") }}</span>
            </h1>
            <div class="button-box">
              <!-- @click="toDownload" -->
              <PagesNewHomeCommonToDownloadBtn
                :id="HERO_FREE_BTN"
                :show-icon="false"
                class="to-download-btn"
                @click="toDownload"
              >
              </PagesNewHomeCommonToDownloadBtn>
              <CommonButton v-if="showAllFeatureBtn" class="btn to-feature-btn" @click="toFeature">
                {{ useTranslateI18n("features.seeAll") }}
              </CommonButton>
            </div>
          </template>
        </div>
        <div class="interval" />
        <div class="video-area">
          <CommonVideo
            :src="getCloudAssets('/images/pages/newHome/home-hero-video.mp4')"
            :poster="getCloudAssets('/images/pages/newHome/home-hero-video-poster.webp')"
            class="video"
            autoplay
            muted
            loop
            :alt="useTranslateI18n('newHome.hero.videoAlt')"
          />
        </div>
      </div>
    </PagesNewHomeCommonContainer>
    <CommonGuideDescent />
  </div>
</template>

<script lang="ts" setup>
import { HERO_FREE_BTN, HERO_ID } from "~/constant/pages/home"
// import Star from "@/assets/icons/pages/home/star.svg"
import { INavbarTheme } from "~/constant/navbar"
import { useAllActivitys, IActivityType } from "~/constant/useAllActivitys"
import { PAYMENT_PAGE_PATH, ALL_FEATURE } from "~/constant/route"

const Gift = getCloudAssets("/images/pages/blackFridays/gift.png")
// const Circle = getCloudAssets("/images/pages/blackFridays/circle.png")
// const Group = getCloudAssets("/images/pages/blackFridays/group.png")

const { hallows_open_type, hallows_open } = useAllActivitys()

const blackFridayDesc = computed(() => {
  return hallows_open_type.value === IActivityType.BlackFriday
    ? useTranslateI18n("payment.blackFridays.t5", [
        { type: "static", class: "dis" },
        useIsNewSubPage().value ? "35%" : "30%",
        { type: "static", class: "dis-1" }
      ])
    : useTranslateI18n("payment.blackFridays.t6", [
        { type: "static", class: "dis" },
        useIsNewSubPage().value ? "35%" : "30%",
        { type: "static", class: "dis-1" }
      ])
})

const showAni = ref(false)
const { _pointNewHomeHeroClick } = useNewHomeTsSdk()

const toDownload = () => {
  _pointNewHomeHeroClick()
}
const toFeature = () => {
  _pointNewHomeHeroClick({
    element_name: "see all features"
  })
  return navigateTo(unref(ALL_FEATURE))
}

const goPricing = () => {
  return navigateTo(unref(PAYMENT_PAGE_PATH))
}

// 避免由于文字变化导致的cls(Cumulative Layout Shift)
const showIcon = ref(false)

const showDark = ref(hallows_open.value)
let windowScrollEvent = null
function initScrollStyle() {
  if (hallows_open.value) {
    useSetNavbarTheme(INavbarTheme.Dark)
  }
  useNavbarCustomBg().value = "transparent"

  windowScrollEvent = useEventListener(window, "scroll", function () {
    let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    showDark.value = hallows_open.value && top <= 0
    if (showDark.value) {
      useSetNavbarTheme(INavbarTheme.Dark)
      useNavbarCustomBg().value = "transparent"
    } else {
      useSetNavbarTheme(INavbarTheme.White)
    }
  })
}
const showAllFeatureBtn = ref(false)
const { showAllFeature, setStatsigFlag } = useStatsig()
const testAllFeature = async () => {
  // AB 测试
  const val = await showAllFeature()
  console.log("------showAllFeature: ", val)
  showAllFeatureBtn.value = val
}
testAllFeature()
onMounted(() => {
  initScrollStyle()
  showIcon.value = true
  if (showAllFeatureBtn.value) {
    // 埋点上报添加AB标识参数
    const params = {
      showAllFeature: true
    }
    setStatsigFlag(params)
  }
})

onBeforeUnmount(() => {
  windowScrollEvent && windowScrollEvent()
  useSetNavbarTheme(INavbarTheme.White)
})
</script>

<style lang="scss" scoped src="./index.scss" />
